<template lang="html">
  <svg class="logo" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 20L20 0L25.625 5.625L5.625 25.625L0 20Z" fill="currentColor"/>
    <path d="M6.875 26.875L13.125 33.125L22.5 23.75L16.25 17.5L6.875 26.875Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.375 34.375L20 40L40 20L34.375 14.375L14.375 34.375ZM16.875 34.375L20 37.5L37.5 20L34.375 16.875L16.875 34.375Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.125 13.125L26.875 6.87499L17.5 16.25L23.75 22.5L33.125 13.125ZM23.75 20L20 16.25L26.875 9.375L30.625 13.125L23.75 20Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
};
</script>

<style lang="stylus" scoped>
.logo
  display: block
  width: (40 / 16)rem
  height: @width
</style>
