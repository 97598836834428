<template lang="html">
  <section class="separator field">
    <hr>
  </section>
</template>

<script>
import field from '../../mixins/field';

export default {
  mixins: [field],
};
</script>

<style lang="stylus" scoped>
</style>
